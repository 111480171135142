import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderComponent.css'; // Custom styles if needed
import slide1 from '../Images/slide1.webp'; // Ensure the correct path to your image

const slidesData = [
  {
    image: slide1,
    title: 'Next Level Service and Maintenance',
    text: 'For the elevator industry',
    ctaText: 'Get a Quote',
    ctaLink: '#',
  },
  {
    image: slide1,
    title: 'Modern Elevators for Modern Buildings',
    text: 'Tailored to your needs',
    ctaText: 'Learn More',
    ctaLink: '#',
  },
  {
    image: slide1,
    title: 'Safety and Reliability',
    text: 'Ensuring smooth vertical transportation',
    ctaText: 'Contact Us',
    ctaLink: '#',
  },
];

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Change slide every 3 seconds
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
            <div key={index} className="slide">
    <img src={slide.image} alt={slide.title} className="slide-image" />
    <div className="slide-content">
        <h2>{slide.title}</h2>
        <p>{slide.text}</p>
        <a href={slide.ctaLink} className="cta-button">{slide.ctaText}</a>
    </div>
</div>

        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
