import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './Screens/Home';
import About from './Screens/About';
import Services from './Screens/Services';
import Contact from './Screens/Contact';
import Donate from './Screens/Donate';
import WhatsAppButton from './WhatsAppButton';
import './App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Donate" element={<Donate />} />
         
          
        </Routes>
        <WhatsAppButton phoneNumber="1234567890" message="Hello! I need some assistance." />
      </div>
    </Router>
  );
};

export default App;
