import React from 'react';
import './Donate.css';
import Footer from '../components/Footer';
import qr from '../Images/QR-Code-PNG-Images 1.png';
function Donate() {
  return (
    <div className="App">
    
      <DonationForm />
      <Footer />
    </div>
  );
}

const Header = () => (
  <header className="header">
    <div className="logo">
      <img src="/path/to/logo.png" alt="Logo" />
      <h1>AL-UMMATI</h1>
    </div>
    <nav>
      <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">About The Quran</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact Us</a></li>
      </ul>
    </nav>
    <button className="donate-button">Donate Now</button>
  </header>
);

const DonationForm = () => (
  <main className="donation">
    
    <div className="donation-amount">
    <h2>Make a Donation</h2>
      <input type="text" placeholder="Enter Donation Amount" />
    </div>
    <div className='donation-form'>
    <div className="details">
      <input type="text" placeholder="First Name" />
      <input type="text" placeholder="Last Name" />
      <input type="email" placeholder="Email" />
      <input type="text" placeholder="Address" />
      <div className="message-qrcode">
        <textarea placeholder="Message"></textarea>
        <div className="qr-code">
          <img src={qr} alt="QR Code for Payment" />
          <p>Scan this QR for Payment</p>
        </div>
      </div>
    </div>
    </div>
  </main>
);



export default Donate;
