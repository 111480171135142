// src/components/Footer.js
import React from 'react';
import './Footer.css';
import Logo from '../Images/dr_elevator.webp';
const Footer = () => {
  return (
    <footer>
      <div className="footer-section">
        {/* <h3>Dr Elevator</h3> */}
        <img src={Logo} alt="Overlay Image" className="drlogo"/>
        <p>
NEXT LEVEL SERVICE AND MAINTENANCE
FOR THE ELEVATOR INDUSTRY</p>
      </div>
      <div className="footer-section">
        <h3>Explore Link</h3>
        <ul>
          <li><a href="../Screens/About.js">About Us</a></li>
          <li><a href="../Screens/Services.js">Our Services</a></li>
          <li><a href="../Screens/Contact.js">Contact Us</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h3>Latest Post</h3>
        <p>Our latest updates and insights.</p>
      </div>
      <div className="footer-section">
        <p>&copy; Dr Elevators</p>
        <p>Elevators, Pune,India</p>
        <p>
NEXT LEVEL SERVICE AND MAINTENANCE
FOR THE ELEVATOR INDUSTRY</p>
      </div>
    </footer>
  );
};

export default Footer;
