import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

import Background from '../Images/Background Website 2.png';
import Logo from '../Images/dr_elevator.webp';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="container">
     
        
        <button className="menu-toggle" onClick={handleMenuToggle}>
          &#9776;
        </button>
        <nav className={isMenuOpen ? 'open' : ''}>
      
          <ul>
          <img src={Logo} alt="Overlay Image" className="drlogo"/>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><button className="donate-btn" ><Link to="/Donate" style={{color:'white'}}>Shop Now</Link></button></li>
          </ul>
        </nav>
    
      </div>
    </header>
  );
};

export default Header;
