// Testimonials.js
import React, { useState, useEffect } from "react";
import "./Testimonials.css"; // Import the CSS file for styling
import t1 from '../Images/testimonials-1.jpg';
import t2 from '../Images/testimonials-2.jpg';
import t3 from '../Images/testimonials-3.jpg';
import t4 from '../Images/testimonials-4.jpg';
import t5 from '../Images/testimonials-5.jpg';
import t6 from '../Images/testimonials-6.jpg';
const testimonialsData = [
  {
    name: "John Doe",
    position: "CEO, Example Corp",
    feedback:
      "This product has significantly improved our workflow. Highly recommended!",
    image:t1, // Placeholder image URL
  },
  {
    name: "Jane Smith",
    position: "Marketing Manager, Sample Inc",
    feedback:
      "The team was professional and delivered everything on time. Great experience!",
    image: t2, // Placeholder image URL
  },
  {
    name: "Alice Johnson",
    position: "Product Designer, Widget Co",
    feedback:
      "Amazing service! The customer support was prompt and very helpful.",
    image: t3, // Placeholder image URL
  },
  {
    name: "Michael Brown",
    position: "Software Engineer, Tech Solutions",
    feedback:
      "An excellent platform with great customer support. Highly efficient!",
    image: t4, // Placeholder image URL
  },
  {
    name: "Emily White",
    position: "Project Manager, Creative Studios",
    feedback:
      "Our team's productivity increased by 30% using this service. Absolutely fantastic!",
    image: t5, // Placeholder image URL
  },
  {
    name: "David Wilson",
    position: "Freelance Developer",
    feedback:
      "A remarkable experience! The tools provided are very user-friendly.",
    image: t6, // Placeholder image URL
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 3 >= testimonialsData.length ? 0 : prevIndex + 3
      );
    }, 3000); // 3000ms = 3 seconds

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, []);

  const visibleTestimonials = testimonialsData.slice(
    currentIndex,
    currentIndex + 3
  );

  return (
    <div className="testimonials">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-cards">
        {visibleTestimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} />
            <h3>{testimonial.name}</h3>
            <p className="position">{testimonial.position}</p>
            <p className="feedback">"{testimonial.feedback}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
