import React from 'react';
import Footer from '../components/Footer'; // Ensure Footer is correctly imported
import './Services.css'; // Import your CSS file for custom styles
import design from '../Images/design.webp';
import anytime from '../Images/24-7.webp';
import spare from "../Images/spare parts.webp";
import installation from  "../Images/installation.webp";
import modernization from "../Images/Modernization and upgrades.webp";
import maintainence from "../Images/maintenance.webp";

// Services data
const servicesData = [
  {
    title: "Maintenance",
    description:
      "Keep your elevators operating at peak performance with our comprehensive maintenance services. From routine checks to minor repairs, we ensure your elevator systems run smoothly, minimizing downtime and disruptions. Our team of dedicated professionals can provide the necessary care to ensure your building's elevators stay in top shape.",
    image: maintainence,
  },
  {
    title: "Modernization",
    description:
      "Bring your elevators into the modern era with our state-of-the-art modernization services. Improve energy efficiency and provide a safer, more comfortable experience for your users. Whether you're upgrading for aesthetics, technology, or safety, we provide a tailored approach to meet your unique needs.",
    image: modernization,
  },
  {
    title: "Installation",
    description:
      "Experience the perfect blend of innovation and safety with our expert installation services. From small residential buildings to towering skyscrapers, we ensure a smooth and efficient installation process for all types of elevators. Trust us to get your vertical transportation to new heights.",
    image:installation,
  },
  {
    title: "Spare Parts",
    description:
      "Keep your elevators running smoothly with our extensive range of high-quality spare parts and components. We offer genuine, high-performance parts for all types of elevators. If you need replacements or upgrades, our team can provide the perfect parts you need to maintain the optimal performance of your elevator systems.",
    image: spare,
  },
  {
    title: "24/7 Service",
    description:
      "When unexpected issues arise, our 24/7 emergency response team is ready to assist. Our technicians are available around the clock to handle any emergency situation, ensuring that your elevators remain operational and safe. Trust us to keep your elevator systems running smoothly at all hours.",
    image: anytime,
  },
  {
    title: "Design & Customization",
    description:
      "Transform your space with our custom design and installation services. From modern aesthetics to classic designs, we offer a range of customization options to meet your specific needs. Create a unique and stylish elevator experience for your building with cutting-edge technology.",
    image: design,
  },
];

const Services = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title text-center">
          <h2>Services</h2>
          <p>
            Discover our comprehensive range of services designed to meet all your elevator needs, from installation to
            maintenance and modernization.
          </p>
        </div>

        <div className="row">
          {servicesData.map((service, index) => (
            <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4">
              <div className="icon-box">
                <img src={service.image} alt={service.title} className="img-fluid service-image" />
                <h4><a href="#">{service.title}</a></h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
       
      </div>
      {/* Footer Component */}
      <Footer />
    </section>
  );
};

export default Services;
