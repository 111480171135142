// src/App.js

import React, { useState } from 'react';
import Header from '../components/Header';
import SliderComponent from '../components/SliderComponent';
import ClientLogosMarquee from '../components/ClientLogosMarquee';
import Footer from '../components/Footer';
import About from './About';
import AboutImage from '../Images/About.png';
import design from '../Images/design.webp';
import anytime from '../Images/24-7.webp';
import spare from "../Images/spare parts.webp";
import installation from "../Images/installation.webp";
import modernization from "../Images/Modernization and upgrades.webp";
import maintainence from "../Images/maintenance.webp";
import Testimonials from '../components/Testimonials';

// Define services data
const servicesData = [
  {
    title: "Maintenance",
    description: "Keep your elevators operating at peak performance with our comprehensive maintenance services. From routine checks to minor repairs, we ensure your elevator systems run smoothly, minimizing downtime and disruptions. Our team of dedicated professionals can provide the necessary care to ensure your building's elevators stay in top shape.",
    image: maintainence,
  },
  {
    title: "Modernization",
    description: "Bring your elevators into the modern era with our state-of-the-art modernization services. Improve energy efficiency and provide a safer, more comfortable experience for your users. Whether you're upgrading for aesthetics, technology, or safety, we provide a tailored approach to meet your unique needs.",
    image: modernization,
  },
  {
    title: "Installation",
    description: "Experience the perfect blend of innovation and safety with our expert installation services. From small residential buildings to towering skyscrapers, we ensure a smooth and efficient installation process for all types of elevators. Trust us to get your vertical transportation to new heights.",
    image: installation,
  },
  {
    title: "Spare Parts",
    description: "Keep your elevators running smoothly with our extensive range of high-quality spare parts and components. We offer genuine, high-performance parts for all types of elevators. If you need replacements or upgrades, our team can provide the perfect parts you need to maintain the optimal performance of your elevator systems.",
    image: spare,
  },
  {
    title: "24/7 Service",
    description: "When unexpected issues arise, our 24/7 emergency response team is ready to assist. Our technicians are available around the clock to handle any emergency situation, ensuring that your elevators remain operational and safe. Trust us to keep your elevator systems running smoothly at all hours.",
    image: anytime,
  },
  {
    title: "Design & Customization",
    description: "Transform your space with our custom design and installation services. From modern aesthetics to classic designs, we offer a range of customization options to meet your specific needs. Create a unique and stylish elevator experience for your building with cutting-edge technology.",
    image: design,
  },
];

const App = () => {
  // Form state management
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Send form data to backend
    fetch("http://localhost/DrElevator_Backend/Backend/submit_contact.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(formData).toString(),
    })
      .then((response) => response.text())
      .then((data) => {
        alert(data); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="App">
  
      <SliderComponent />
      <div className="content-wrapper">
        <div className="container">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-md-6">
              <img src={AboutImage} alt="About Us" className="img-fluid About" />
            </div>

            {/* Text Section */}
            <div className="col-md-6">
              <h1>About Us</h1>
              <p>
                At Dr. Elevator, we specialize in transforming the way you move within your buildings. Since our inception, we’ve dedicated ourselves to crafting elevators that are safe, dependable, and energy-efficient, suitable for all residential, commercial, and industrial spaces. Our expert team designs and installs each elevator to meet the highest industry standards, ensuring seamless integration and superior performance. We pride ourselves on our innovation, quality workmanship, and commitment to customer satisfaction. Choose Dr. Elevator for a modern and efficient elevator experience tailored to your specific needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section id="services" className="services section-bg">
        <div className="container">
          <div className="section-title text-center">
            <h2>Services</h2>
            <p>
              Discover our comprehensive range of services designed to meet all your elevator needs, from installation to maintenance and modernization.
            </p>
          </div>

          <div className="row">
            {servicesData.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4">
                <div className="icon-box">
                  <img src={service.image} alt={service.title} className="img-fluid" />
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Form on Home Page */}
      <div className="contact-container">
      <section className="contact-content">
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=..."
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Location Map"
          ></iframe>
        </div>
        <div className="contact-form">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Case Description"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </section>

      <div className="contact-info">
        <div className="info-item">
          <i className="fas fa-phone-alt"></i>
          <p>+91 9876543210</p>
        </div>
        <div className="info-item">
          <i className="fas fa-envelope"></i>
          <p>info@DrElevator.com</p>
        </div>
        <div className="info-item">
          <i className="fas fa-map-marker-alt"></i>
          <p>Elevators, Pune, India</p>
        </div>
      </div>

     
    </div>

      <Testimonials />
      <Footer />
    </div>
  );
};

export default App;
