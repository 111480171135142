import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Importing WhatsApp icon from react-icons

const WhatsAppButton = ({ phoneNumber, message }) => {
  // Construct the WhatsApp URL with the phone number and message
  const whatsappUrl = `https://wa.me/${9623131375}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        textDecoration: 'none',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000'
      }}
    >
      <FaWhatsapp size={24} /> {/* WhatsApp icon */}
    </a>
  );
};

export default WhatsAppButton;
