import React from 'react';
import './About.css'; // Assuming your custom CSS is here
import Footer from '../components/Footer';
import AboutImage from '../Images/About.png'; // Renamed the image import
import Testimonials from '../components/Testimonials';
const About = () => {
  return (
    <div className="about-container">
      {/* Main Content Section */}
      <div className="content-wrapper">
        <div className="container">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-md-6">
              <img src={AboutImage} alt="About Us" className="img-fluid About" />
            </div>

            {/* Text Section */}
            <div className="col-md-6">
              <h1>About Us</h1>
              <p>
                At Dr. Elevator, we specialize in transforming the way you move within your buildings. Since our inception, we’ve dedicated ourselves to crafting elevators that are safe, dependable, and energy-efficient, suitable for all residential, commercial, and industrial spaces. Our expert team designs and installs each elevator to meet the highest industry standards, ensuring seamless integration and superior performance. We pride ourselves on our innovation, quality workmanship, and commitment to customer satisfaction. Choose Dr. Elevator for a modern and efficient elevator experience tailored to your specific needs.
              </p>
            </div>
          </div>
        </div>
      </div>
<Testimonials/>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default About;
